import { useState, useRef, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNodeApi } from "../../hooks/useNodeApi";
import { FillExcelLogResponse, getUserDocument } from "../../services/api";
import { Button } from "../ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { ExternalLink, Search, ChevronRight, FileText } from "lucide-react";
import toast from 'react-hot-toast';
import { Input } from "../ui/input";
import { withRequiredAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import axios from 'axios';
import DocumentPreviewDialog from '../DocumentPreviewDialog';

interface FillExcelDetailResultsProps {
  fillExcelLog: FillExcelLogResponse | null;
  accessToken: string | null;
}

export const FillExcelDetail = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const navigate = useNavigate();
  const { id: fillExcelLogId } = useParams<{ id: string }>();
  const { getFillExcelLogData } = useNodeApi(accessToken);
  const [fillExcelLog, setFillExcelLog] = useState<FillExcelLogResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const lastPollTimeRef = useRef<number>(0);

  usePolling(
    () => {
      if (!fillExcelLogId) {
        throw new Error('No excel log ID provided.');
      }
      return getFillExcelLogData(fillExcelLogId);
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      continuePollingOnSuccess: true,
      isComplete: (result) => {
        return result?.status === 'completed';
      },
      onSuccess: (result) => {
        setFillExcelLog(result);
        setError(null);
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          navigate('/404');
          return false;
        }
        setError(error instanceof Error ? error.message : 'Unknown error');
        return true;
      }
    }
  );

  if (isLoading && !fillExcelLog) {
    return <FillExcelDetailSkeleton fillExcelLog={fillExcelLog} />;
  }

  if (error) {
    toast.error(error);
  }

  return <FillExcelDetailResults fillExcelLog={fillExcelLog} accessToken={accessToken} />;
});

function FillExcelDetailResults({ fillExcelLog, accessToken }: FillExcelDetailResultsProps) {
  const navigate = useNavigate();
  const { exportFillExcelLog, getPdfUrl } = useNodeApi(accessToken);
  const [isExporting, setIsExporting] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewFileType, setPreviewFileType] = useState<'pdf' | 'excel'>('pdf');
  const [documentMap, setDocumentMap] = useState<Record<string, string>>({});
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);

  useEffect(() => {
    const fetchDocumentDetails = async () => {
      if (!fillExcelLog?.user_document_ids?.length) return;

      setIsLoadingDocuments(true);
      try {
        const newDocumentMap: Record<string, string> = {};
        await Promise.all(
          fillExcelLog.user_document_ids.map(async (docId) => {
            const details = await getUserDocument(docId, accessToken);
            newDocumentMap[docId] = details.filename;
          })
        );
        setDocumentMap(newDocumentMap);
      } catch (error) {
        console.error("Failed to fetch document details:", error);
        toast.error("Failed to load document details");
      } finally {
        setIsLoadingDocuments(false);
      }
    };

    fetchDocumentDetails();
  }, [fillExcelLog?.user_document_ids, accessToken]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredRows = useMemo(() => {
    if (!fillExcelLog || !fillExcelLog.result || !fillExcelLog.result.rows) {
      return [];
    }

    return fillExcelLog.result.rows.filter((row) => {
      const searchLower = searchQuery.toLowerCase();
      return row.data.some((value) =>
        value && value.toString().toLowerCase().includes(searchLower)
      );
    });
  }, [fillExcelLog, searchQuery]);

  const getFileType = (filename: string): 'pdf' | 'excel' => {
    if (!filename || typeof filename !== 'string') {
      return 'pdf';
    }

    const normalizedFilename = filename.trim().toLowerCase();

    const SUPPORTED_FORMATS = {
      excel: ['.xlsx'],
      pdf: ['.pdf']
    } as const;

    for (const [type, extensions] of Object.entries(SUPPORTED_FORMATS)) {
      if (extensions.some(ext => normalizedFilename.endsWith(ext))) {
        return type as 'pdf' | 'excel';
      }
    }

    return 'pdf';
  };

  const handleDocumentClick = async (documentId: string) => {
    try {
      const url = await getPdfUrl(documentId);
      const filename = documentMap[documentId];
      const fileType = getFileType(filename);

      setPreviewFileType(fileType);
      setPreviewUrl(url);
      setIsPreviewOpen(true);
    } catch (error) {
      console.error("Failed to fetch document URL:", error);
      toast.error("Failed to load document preview");
    }
  };

  if (!fillExcelLog || !fillExcelLog.result) {
    return <FillExcelDetailSkeleton fillExcelLog={fillExcelLog} />;
  }

  const { header, rows } = fillExcelLog.result;

  if (!header || header.length === 0 || !rows || rows.length === 0) {
    toast.error('Excel data is empty or invalid.');
    return null;
  }

  const handleExport = async () => {
    if (!fillExcelLog?._id) return;

    setIsExporting(true);
    try {
      const downloadUrl = await exportFillExcelLog(fillExcelLog._id);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.download = `fillexcel_${fillExcelLog._id}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Export successful. Your download should begin shortly.');
    } catch (error) {
      console.error('Export failed:', error);
      toast.error('Failed to export excel. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div>
      <div className="h-full bg-white">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center text-md text-gray-500">
                  <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate('/excel')}>Excel</span>
                  <ChevronRight className="h-4 w-4 mx-2" />
                  <span className="font-medium text-gray-900">Details</span>
                </div>
              </div>

              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">
                  <div className="relative">
                    <Input
                      type="text"
                      placeholder="Search"
                      className="pl-8 pr-4 py-1 w-48 h-9"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  </div>
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                  onClick={handleExport}
                  disabled={isExporting}
                >
                  <ExternalLink className="h-4 w-4 mr-2" />
                  {isExporting ? 'Exporting...' : 'Export'}
                </Button>
              </div>

              {fillExcelLog?.user_document_ids && fillExcelLog.user_document_ids.length > 0 ? (
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-4">Uploaded documents</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {fillExcelLog?.user_document_ids?.map((docId, index) => (
                      <div
                        key={index}
                        onClick={() => handleDocumentClick(docId)}
                        className="flex items-center p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 cursor-pointer"
                      >
                        <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center mr-3">
                          <FileText className="w-4 h-4 text-purple-600" />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm font-medium text-gray-900">
                            {isLoadingDocuments
                              ? "Loading..."
                              : documentMap[docId] || `Document ${index + 1}`}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-4">Uploaded documents</h3>
                  <div className="text-gray-500 p-4 border border-dashed rounded-lg">
                    No documents uploaded yet
                  </div>
                </div>
              )}

              <Table className="border-collapse border border-gray-200">
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[40px] border-y border-l border-gray-200">#</TableHead>
                    {header.map((columnName, index) => (
                      <TableHead key={index} className="border border-gray-200">
                        {columnName}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="border-y border-l border-gray-200">{index + 1}</TableCell>
                      {row.data?.map((value, dataIndex) => (
                        <TableCell key={dataIndex} className="border border-gray-200">{value ?? ''}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <DocumentPreviewDialog
                isOpen={isPreviewOpen}
                onClose={() => {
                  setPreviewUrl("");
                  setIsPreviewOpen(false);
                }}
                previewUrl={previewUrl}
                fileType={previewFileType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface FillExcelDetailSkeletonProps extends WithLoggedInAuthInfoProps {
  fillExcelLog: FillExcelLogResponse | null;
}

const FillExcelDetailSkeleton = withRequiredAuthInfo(function FillExcelDetailSkeleton({
  fillExcelLog
}: FillExcelDetailSkeletonProps) {
  const navigate = useNavigate();
  const columnCount = fillExcelLog?.result?.header?.length || 3;

  return (
    <div>
      <div className="h-full bg-white">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center text-md text-gray-500">
                  <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate('/excel')}>Excel</span>
                  <ChevronRight className="h-4 w-4 mx-2" />
                  <span className="font-medium text-gray-900">Details</span>
                </div>
              </div>

              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">
                  <div className="relative">
                    <Input
                      type="text"
                      placeholder="Search"
                      className="pl-8 pr-4 py-1 w-48 bg-white border-gray-300 text-gray-900 focus:ring-0 h-9"
                      disabled
                    />
                    <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  </div>
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                  disabled
                >
                  <ExternalLink className="h-4 w-4 mr-2" />
                  Export
                </Button>
              </div>

              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-4">Uploaded documents</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {[...Array(1)].map((_, index) => (
                    <div
                      key={index}
                      className="flex items-center p-4 rounded-lg border border-gray-200 bg-white"
                    >
                      <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center mr-3">
                        <div className="w-4 h-4 bg-gray-200 rounded animate-pulse" />
                      </div>
                      <div className="flex flex-col space-y-1">
                        <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
                        <div className="h-3 w-16 bg-gray-200 rounded animate-pulse" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <Table className="border-collapse border border-gray-200">
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[40px] border-y border-l border-gray-200">#</TableHead>
                    {[...Array(columnCount)].map((_, index) => (
                      <TableHead key={index} className="border border-gray-200">
                        <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {[...Array(5)].map((_, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell className="border-y border-l border-gray-200">{rowIndex + 1}</TableCell>
                      {[...Array(columnCount)].map((_, colIndex) => (
                        <TableCell key={colIndex} className="border border-gray-200">
                          <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})
