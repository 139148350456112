import { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";
import { CircleCheck, ChevronRight, FileText, XCircle } from "lucide-react";
import { withRequiredAuthInfo } from "@propelauth/react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import DocumentPreviewDialog from "../DocumentPreviewDialog";
import { useNodeApi } from "../../hooks/useNodeApi";
import { WorkflowStep as ApiWorkflowStep, getWorkflowV1, WorkflowExecutionLogResponse } from "../../services/api";
import { usePolling } from "../../lib/usePolling";
import { WorkflowExecutionStatus } from "../../services/api";
import { toast } from "react-hot-toast";
import { PromptInput } from "../core/PromptInput";
import faiLogo from '../../assets/fai-logo-short.svg';
import { Canvas } from "../canvas/Canvas";
import { GuidelinesDetail } from "../uwaudit/GuidelinesDetail";
import { UwAuditDetail } from "../uwaudit/UwAuditDetail";

interface WorkflowPresentationProps {
  executionId: string;
  accessToken: string | null;
  isInCanvas?: boolean;
}

interface UIWorkflowStep {
  id: string;
  title: string;
  stepType: string;
  documentTypes?: string[];
  documents?: {
    name: string;
    documentId: string;
    fileType?: string;
    className?: string;
  }[];
  submissionType?: string;
  guidelines?: string[];
  titles?: string[];
  auditLink?: string;
}

interface Message {
  id: string;
  content: string;
  role: 'user' | 'assistant';
  timestamp: Date;
}

const transformStepToUIFormat = (step: ApiWorkflowStep): UIWorkflowStep => {
  if (step.step_type === 'classify_submission_type') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      submissionType: step.output?.result as string
    };
  }

  if (step.step_type === 'classify_documents' && step.output?.result) {
    const documents: {
      name: string;
      documentId: string;
      fileType?: string;
      className: string;
    }[] = [];
    let documentTypes: string[] = [];

    documentTypes = Object.keys(step.output.result);

    Object.entries(step.output.result).forEach(([className, docs]) => {
      if (Array.isArray(docs)) {
        const transformedDocs = docs.map(doc => ({
          name: doc.filename,
          documentId: doc.user_document_id,
          fileType: doc.filename.split('.').pop()?.toLowerCase(),
          className
        }));
        documents.push(...transformedDocs);
      }
    });

    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      documents: documents.length > 0 ? documents : undefined,
      documentTypes: documentTypes.length > 0 ? documentTypes : undefined,
    };
  }

  if (step.step_type === 'extract_guidelines') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      guidelines: step.output?.guidelines,
      titles: step.output?.titles
    };
  }

  if (step.step_type === 'check_guidelines') {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      auditLink: step.output?.link_to_audit_details
    };
  }

  return {
    id: step.step_type,
    title: step.title,
    stepType: step.step_type
  };
};

const WorkflowPresentation = ({
  executionId,
  accessToken,
  isInCanvas: initialIsInCanvas = false
}: WorkflowPresentationProps) => {
  const navigate = useNavigate();
  const [workflowName, setWorkflowName] = useState<string>("");
  const [workflowId, setWorkflowId] = useState<string>("");
  const [steps, setSteps] = useState<UIWorkflowStep[]>([]);
  const [executionLog, setExecutionLog] = useState<WorkflowExecutionLogResponse | null>(null);
  const [previewDocument, setPreviewDocument] = useState<{ url: string, fileType?: string } | null>(null);
  const [selectedDocType, setSelectedDocType] = useState<string>("");
  const selectedDocTypeRef = useRef<string>("");
  const { fetchWorkflowExecutionLog, getPdfUrl } = useNodeApi(accessToken);
  const lastPollTimeRef = useRef(0);
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [expandedSteps, setExpandedSteps] = useState<{ [key: string]: boolean }>({});
  const [rightContent, setRightContent] = useState<React.ReactNode | null>(null);
  const [isInCanvas, setIsInCanvas] = useState(initialIsInCanvas);

  const isWorkflowComplete = useCallback((log: WorkflowExecutionLogResponse) => {
    return log.status === WorkflowExecutionStatus.COMPLETED ||
      log.status === WorkflowExecutionStatus.FAILED ||
      log.status === WorkflowExecutionStatus.SUSPENDED;
  }, []);

  useEffect(() => {
    if (workflowId) {
      getWorkflowV1(workflowId, accessToken).then(workflow => setWorkflowName(workflow.name));
    }
  }, [workflowId]);  // eslint-disable-line react-hooks/exhaustive-deps

  usePolling(
    async () => {
      const log = await fetchWorkflowExecutionLog(executionId);
      return log;
    },
    {
      interval: 2000,
      enabled: true,
      continuePollingOnSuccess: true,
      isComplete: isWorkflowComplete,
      lastPollTimeRef,
      onSuccess: (log: WorkflowExecutionLogResponse) => {
        setExecutionLog(log);
        setWorkflowId(log.request.workflow_id);
        if (log.result?.steps) {
          const uiSteps = log.result.steps.map(transformStepToUIFormat);
          setSteps(uiSteps);
        }
        setIsLoading(false);
      },
      onError: (error: unknown) => {
        console.error('Failed to fetch workflow execution log:', error);
        toast.error('Failed to fetch workflow status');
        setIsLoading(false);
      }
    }
  );

  useEffect(() => {
    if (steps && steps.length > 0 && !selectedDocTypeRef.current) {
      const classifyStep = steps.find(step => step.stepType === 'classify_documents');
      const documentTypes = classifyStep?.documentTypes;

      if (documentTypes && documentTypes.length > 0) {
        setSelectedDocType(documentTypes[0]);
        selectedDocTypeRef.current = documentTypes[0];
      }
    }
  }, [steps]);

  const handleDocTypeChange = (value: string) => {
    setSelectedDocType(value);
    selectedDocTypeRef.current = value;
  };

  const filterDocumentsByType = (step: UIWorkflowStep) => {
    if (step.stepType !== 'classify_documents' || !step.documents) {
      return [];
    }
    return step.documents.filter(doc => doc.className === selectedDocType);
  };

  const getStepStatus = useCallback((stepId: string) => {
    if (!executionLog) return 'pending';
    if (!executionLog.result) return 'pending';

    if (executionLog.status === WorkflowExecutionStatus.FAILED) {
      return 'failed';
    }

    const currentStepIndex = executionLog.result.steps.findIndex(s => s.step_type === stepId);

    if (executionLog.status === WorkflowExecutionStatus.COMPLETED) {
      return 'completed';
    }

    if (executionLog.status === WorkflowExecutionStatus.RUNNING) {
      const lastIncompleteIndex = executionLog.result.steps.findIndex(
        step => !step.output || Object.keys(step.output).length === 0
      );

      if (lastIncompleteIndex === -1) {
        return 'completed';
      }

      if (currentStepIndex === lastIncompleteIndex) {
        return 'running';
      }

      return currentStepIndex < lastIncompleteIndex ? 'completed' : 'pending';
    }

    return 'pending';
  }, [executionLog]);

  const getLoadingText = (stepTitle: string) => {
    if (stepTitle.toLowerCase().includes('classify')) return 'Classifying documents...';
    if (stepTitle.toLowerCase().includes('extract')) return 'Extracting data...';
    return 'Processing...';
  };

  const handleDocumentClick = async (doc: { documentId: string, fileType?: string }) => {
    if (doc.fileType === 'pdf') {
      try {
        const url = await getPdfUrl(doc.documentId);
        setPreviewDocument({ url, fileType: doc.fileType });
      } catch (error) {
        console.error('Failed to fetch PDF URL:', error);
        toast.error('Unable to preview document. Please try again later.');
      }
    }
  };

  const handleSendMessage = (message: string) => {
    if (!message.trim()) return;

    const userMessage: Message = {
      id: Math.random().toString(36).substr(2, 9),
      content: message,
      role: 'user',
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);

    setTimeout(() => {
      const aiMessage: Message = {
        id: Math.random().toString(36).substr(2, 9),
        content: "This is a simulated AI response. Integrate with your data.",
        role: 'assistant',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, aiMessage]);
    }, 1000);
  };

  const renderShimmer = () => (
    <div>
      <div className="bg-white py-8 px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center text-md">
            <div className="h-5 w-32 bg-gray-200 rounded animate-pulse" />
            <ChevronRight className="h-4 w-4 mx-2 text-gray-300" />
            <div className="h-5 w-16 bg-gray-200 rounded animate-pulse" />
          </div>
        </div>
      </div>


      <div className="flex-1 px-6 pb-4">
        <div className="max-w-7xl mx-auto w-full">
          <div className="max-w-3xl">

            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
              <div className="p-6">
                <div className="relative">

                  <div className="absolute left-[11px] top-[24px] bottom-4 w-[2px] bg-gray-100" />


                  <div className="space-y-8">
                    {[1, 2, 3, 4].map((index) => (
                      <div key={index} className="relative">
                        <div className="flex items-start">

                          <div className="relative z-10 mr-4 rounded-full bg-white p-0.5">
                            <div className="w-5 h-5 rounded-full bg-gray-200 animate-pulse" />
                          </div>


                          <div className="flex-1 pt-1">
                            <div className="flex items-center justify-between mb-4">
                              <div className="h-5 w-48 bg-gray-200 rounded animate-pulse" />
                              <div className="h-5 w-5 rounded-full bg-gray-200 animate-pulse" />
                            </div>


                            <div className="space-y-3">
                              {index === 2 && (
                                <>
                                  <div className="h-9 w-[180px] bg-gray-200 rounded animate-pulse" />
                                  <div className="grid grid-cols-2 gap-4">
                                    {[1, 2].map((i) => (
                                      <div key={i} className="flex items-center p-2 rounded-md border border-gray-100">
                                        <div className="w-8 h-8 rounded-lg bg-gray-200 animate-pulse mr-3" />
                                        <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                              {(index === 3 || index === 4) && (
                                <div className="h-9 w-28 bg-gray-200 rounded animate-pulse" />
                              )}
                            </div>
                          </div>
                        </div>


                        {index < 4 && (
                          <div className="h-px bg-gray-200 my-6 ml-10" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>


            <div className="sticky bottom-5 left-0 right-0 mt-4">
              <div className="bg-white border border-gray-200 rounded-lg p-4">
                <div className="h-10 bg-gray-200 rounded animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <DocumentPreviewDialog
        isOpen={false}
        onClose={() => void 0}
        previewUrl=""
        fileType="pdf"
      />
    </div>
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, isInCanvas]);

  const toggleStep = (stepId: string) => {
    setExpandedSteps(prev => ({
      ...prev,
      [stepId]: !prev[stepId]
    }));
  };

  useEffect(() => {
    if (steps.length > 0) {
      const isWorkflowDone = executionLog?.status === WorkflowExecutionStatus.COMPLETED ||
                            executionLog?.status === WorkflowExecutionStatus.FAILED;

      if (isWorkflowDone) {
        const initialExpanded = steps.reduce((acc, step, index) => {
          acc[step.id] = index === steps.length - 1;
          return acc;
        }, {} as { [key: string]: boolean });
        setExpandedSteps(initialExpanded);
      } else {
        const initialExpanded = steps.reduce((acc, step) => {
          acc[step.id] = true;
          return acc;
        }, {} as { [key: string]: boolean });
        setExpandedSteps(initialExpanded);
      }
    }
  }, [steps, executionLog?.status]);

  const handleGuidelinesClick = () => {
    if (!isInCanvas) {
      setIsInCanvas(true);
      setRightContent(
        <GuidelinesDetail
          initialExecutionId={executionId}
          isEmbedded={true}
        />
      );
    } else {
      setRightContent(
        <GuidelinesDetail
          initialExecutionId={executionId}
          isEmbedded={true}
        />
      );
    }
  };

  const handleAuditClick = (auditLink: string) => {
    const auditId = auditLink.split('/').pop();

    if (!isInCanvas) {
      setIsInCanvas(true);
      setRightContent(
        <UwAuditDetail
          initialAuditId={auditId}
          isEmbedded={true}
        />
      );
    } else {
      setRightContent(
        <UwAuditDetail
          initialAuditId={auditId}
          isEmbedded={true}
        />
      );
    }
  };

  const handleCanvasClose = () => {
    setIsInCanvas(false);
    setRightContent(null);
  };

  const workflowContent = (
    <div className="h-full flex flex-col">
      <div className="py-8 px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center text-md text-gray-500">
            <span
              className="hover:text-gray-700 cursor-pointer"
              onClick={() => navigate(`/workflows/${executionLog?.request.workflow_id}`)}
            >
              {workflowName}
            </span>
            <ChevronRight className="h-4 w-4 mx-2" />
            <span className="font-medium text-gray-900">Steps</span>
          </div>
        </div>
      </div>

      <div className="flex-1 px-6 pb-4">
        <div className="max-w-7xl mx-auto w-full">
          <div className="max-w-3xl">
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                <div className="p-6">
                  <div className="relative">
                    <div className="absolute left-[11px] top-[24px] bottom-4 w-[2px] bg-gray-100" />

                    <div className="space-y-8">
                      {steps.map((step, index) => (
                        <div key={step.id} className="relative">
                          <div className="flex items-start">
                            <div className={cn(
                              "relative z-10 mr-4 rounded-full bg-white p-0.5",
                              getStepStatus(step.id) === 'failed' ? "text-red-600" : "text-purple-600"
                            )}>
                              {getStepStatus(step.id) === 'completed' ? (
                                <CircleCheck className="w-5 h-5" />
                              ) : getStepStatus(step.id) === 'failed' ? (
                                <XCircle className="w-5 h-5" />
                              ) : getStepStatus(step.id) === 'running' ? (
                                <div className="w-5 h-5 rounded-full bg-purple-600 animate-pulse" />
                              ) : (
                                <div className="w-5 h-5 rounded-full border-2 border-gray-200" />
                              )}
                            </div>

                            <div className="flex-1 pt-1">
                              <div className="flex items-center justify-between mb-4">
                                <h3 className="text-sm font-medium">{step.title}</h3>
                                <button
                                  onClick={() => toggleStep(step.id)}
                                  className="p-1 hover:bg-gray-50 rounded-full"
                                >
                                  <ChevronRight
                                    className={cn(
                                      "w-5 h-5 text-gray-400 transition-transform duration-200",
                                      expandedSteps[step.id] ? "transform rotate-90" : ""
                                    )}
                                  />
                                </button>
                              </div>

                              <div className={cn(
                                "transition-all duration-200 overflow-hidden",
                                expandedSteps[step.id] ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
                              )}>
                                {getStepStatus(step.id) === 'running' && (
                                  <p className="text-sm text-gray-500 mb-4">
                                    {getLoadingText(step.title)}
                                  </p>
                                )}

                                {step.stepType === 'classify_submission_type' &&
                                  step.submissionType &&
                                  getStepStatus(step.id) === 'completed' && (
                                    <div className="mt-2">
                                      <p className="text-sm text-gray-900">
                                        <span className="font-medium">{step.submissionType}</span>
                                      </p>
                                    </div>
                                  )}

                                {step.stepType === 'classify_documents' && step.documentTypes && (
                                  <div className="space-y-4">
                                    <Select
                                      value={selectedDocType}
                                      onValueChange={handleDocTypeChange}
                                    >
                                      <SelectTrigger className="w-[180px] focus:ring-0">
                                        <SelectValue placeholder="Select document type" />
                                      </SelectTrigger>
                                      <SelectContent className="bg-white">
                                        {step.documentTypes.map(type => (
                                          <SelectItem key={type} value={type}>{type}</SelectItem>
                                        ))}
                                      </SelectContent>
                                    </Select>

                                    <div className="grid grid-cols-2 gap-4">
                                      {filterDocumentsByType(step).map((doc) => (
                                        <div
                                          key={doc.name}
                                          className="flex items-center p-2 hover:bg-gray-50 rounded-md cursor-pointer"
                                          onClick={() => handleDocumentClick(doc)}
                                        >
                                          <div className="w-8 h-8 rounded-lg bg-purple-50 flex items-center justify-center mr-3">
                                            <FileText className="w-4 h-4 text-purple-600" />
                                          </div>
                                          <div className="flex flex-col">
                                            <span className="text-sm text-gray-900">{doc.name}</span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}

                                {step.stepType === 'extract_guidelines' && getStepStatus(step.id) === 'completed' && (
                                  <div className="mt-2">
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      onClick={handleGuidelinesClick}
                                    >
                                      View Guidelines
                                    </Button>
                                  </div>
                                )}

                                {step.stepType === 'check_guidelines' &&
                                  step.auditLink &&
                                  getStepStatus(step.id) === 'completed' && (
                                    <div className="mt-2">
                                      <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={() => step.auditLink && handleAuditClick(step.auditLink)}
                                      >
                                        View Output
                                      </Button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>

                          {index < steps.length - 1 && (
                            <div className="h-px bg-gray-200 my-6 ml-10" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
            </div>
            <div className="min-h-[calc(100vh-38rem)] overflow-y-auto">
              {messages.length > 0 && (
                <div className="mt-8 space-y-4">
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={cn(
                        "flex",
                        message.role === 'assistant' ? "justify-start" : "justify-end"
                      )}
                    >
                      {message.role === 'assistant' && (
                        <div className="w-8 h-8 flex items-center justify-center mr-2">
                          <img src={faiLogo} alt="AI" />
                        </div>
                      )}
                      <div
                        className={cn(
                          "max-w-[80%] rounded-lg p-2",
                          message.role === 'assistant'
                            ? "text-gray-900"
                            : "bg-purple-300 text-gray-900"
                        )}
                      >
                        {message.content}
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="sticky bottom-5 left-0 right-0 px-6">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-3xl">
            <div className="bg-white border border-gray-200 rounded-lg">
              <PromptInput onSend={handleSendMessage} />
            </div>
          </div>
        </div>
      </div>

        <DocumentPreviewDialog
          isOpen={!!previewDocument}
          onClose={() => setPreviewDocument(null)}
          previewUrl={previewDocument?.url || ''}
          fileType={previewDocument?.fileType as 'pdf' | 'excel' | 'image'}
        />
    </div>
  );

  return isLoading ? renderShimmer() : (
    isInCanvas ? (
      <Canvas
        leftContent={workflowContent}
        rightContent={rightContent || <div className="p-10">Select an action to view details</div>}
        onClose={handleCanvasClose}
      />
    ) : (
      <div>
        {workflowContent}
      </div>
    )
  );
};

export const WorkflowV1 = withRequiredAuthInfo(({
  accessToken,
  isInCanvas
}: {
  accessToken: string | null;
  isInCanvas?: boolean;
}) => {
  const { executionId } = useParams<{ executionId: string }>();

  if (!executionId) {
    return <div>No execution ID provided</div>;
  }

  return (
    <WorkflowPresentation
      executionId={executionId}
      accessToken={accessToken}
      isInCanvas={isInCanvas}
    />
  );
});
