import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import { cn } from "../../lib/utils";
import { CircleCheck, ChevronRight, FileText } from "lucide-react";
import { SubmissionAIChat } from "./SubmissionAIChat";
import { withRequiredAuthInfo } from "@propelauth/react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import DocumentPreviewDialog from "../DocumentPreviewDialog";
import { useNodeApi } from "../../hooks/useNodeApi";

interface WorkflowStep {
  id: string;
  title: string;
  status: 'completed' | 'current' | 'pending' | 'requires_action';
  type: 'document' | 'output' | 'external' | 'manual' | 'review';
  sectionTitle?: string;
  documents?: {
    name: string;
    url: string;
    fileType?: string;
  }[];
}

interface WorkflowPresentationProps {
  steps: WorkflowStep[];
  accessToken: string | null;
}

const WorkflowPresentation = ({
  steps,
  accessToken
}: WorkflowPresentationProps) => {
  const navigate = useNavigate();
  const { id: submissionId } = useParams<{ id: string }>();
  const [previewDocument, setPreviewDocument] = useState<{url: string, fileType?: string} | null>(null);
  const [selectedDocType, setSelectedDocType] = useState<string>("ACORD");
  const { getExtractLogsHistory } = useNodeApi(accessToken);

  const handleViewOutput = async (stepTitle: string) => {
    if (!submissionId) return;

    if (stepTitle.toLowerCase().includes('extract')) {
      try {
        const logs = await getExtractLogsHistory();
        if (logs && logs.length > 0) {
          const latestLogId = logs[0]._id;
          navigate(`/extract/logs/${latestLogId}`);
        } else {
          console.error('No extraction logs found');
        }
      } catch (error) {
        console.error('Failed to fetch extraction logs:', error);
      }
    } else {
      navigate(`/submission/email/${submissionId}`);
    }
  };

  const filterDocumentsByType = (
    documents: WorkflowStep['documents'],
    stepTitle: string,
    selectedType: string
  ) => {
    if (!documents || !stepTitle.includes('Classified')) {
      return documents || [];
    }

    return documents.filter(doc => {
      const docName = doc.name.toUpperCase();
      const docType = selectedType.toUpperCase();

      return (
        docName.includes(docType) ||
        (docType === 'LOSS RUNS' && docName.includes('LOSS_RUNS')) ||
        doc.name.toLowerCase().includes('view')
      );
    });
  };

  return (
    <div>
      <div className="bg-gray-50 py-8 px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center text-md text-gray-500">
            <span
              className="hover:text-gray-700 cursor-pointer"
              onClick={() => navigate('/submissions')}
            >
              Submissions
            </span>
            <ChevronRight className="h-4 w-4 mx-2" />
            <span className="font-medium text-gray-900">Milton Flagstaff</span>
          </div>
          <h1 className="text-xl font-semibold mt-4">Workflows</h1>
        </div>
      </div>

      <div className="flex-1 flex overflow-hidden bg-gray-50 px-6">
        <div className="flex gap-4 w-full max-w-7xl mx-auto">
          <div className="flex-1">
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
              <ScrollArea className="h-[calc(100vh-145px)]">
                <div className="p-6">
                  <div className="relative">
                    <div className="absolute left-[11px] top-[24px] bottom-4 w-[2px] bg-gray-100" />

                    <div className="space-y-8">
                      {steps.map((step) => (
                        <div key={step.id} className="relative">
                          <div className="flex items-start">
                            <div className={cn(
                              "relative z-10 mr-4 rounded-full bg-white p-0.5",
                              "text-purple-600"
                            )}>
                              <CircleCheck className="w-5 h-5" />
                            </div>

                            <div className="flex-1 pt-1">
                              <h3 className="text-sm font-medium mb-4">{step.title}</h3>

                              {step.documents && (
                                <div className="space-y-4">
                                  {step.title.includes('Classified') && (
                                    <Select
                                      value={selectedDocType}
                                      onValueChange={setSelectedDocType}
                                    >
                                      <SelectTrigger className="w-[120px] focus:ring-0">
                                        <SelectValue placeholder="ACORD" />
                                      </SelectTrigger>
                                      <SelectContent className="bg-white">
                                        <SelectItem value="ACORD">ACORD</SelectItem>
                                        <SelectItem value="Loss Runs">Loss Runs</SelectItem>
                                        <SelectItem value="SOV">SOV</SelectItem>
                                      </SelectContent>
                                    </Select>
                                  )}

                                  {step.sectionTitle && (
                                    <h4 className="text-sm text-gray-900 mb-2">{step.sectionTitle}</h4>
                                  )}

                                  <div className="space-y-2">
                                    {step.documents.some(doc => doc.name.toLowerCase().includes('view')) && (
                                      <Button
                                        variant="outline"
                                        className="h-9 px-4"
                                        onClick={() => handleViewOutput(step.title)}
                                      >
                                        View output
                                      </Button>
                                    )}

                                    <div className="grid grid-cols-2 gap-4">
                                      {filterDocumentsByType(
                                        step.documents.filter(doc => !doc.name.toLowerCase().includes('view')),
                                        step.title,
                                        selectedDocType
                                      ).map((doc) => (
                                        <div
                                          key={doc.name}
                                          className="flex items-center p-2 hover:bg-gray-50 rounded-md cursor-pointer"
                                          onClick={() => setPreviewDocument({ url: doc.url, fileType: doc.fileType })}
                                        >
                                          <div className="w-8 h-8 rounded-lg bg-purple-50 flex items-center justify-center mr-3">
                                            <FileText className="w-4 h-4 text-purple-600" />
                                          </div>
                                          <div className="flex flex-col">
                                            <span className="text-sm text-gray-900">{doc.name}</span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </ScrollArea>
            </div>
          </div>

          <div className="w-80">
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden h-[calc(100vh-145px)]">
              <SubmissionAIChat />
            </div>
          </div>
        </div>
      </div>

      <DocumentPreviewDialog
        isOpen={!!previewDocument}
        onClose={() => setPreviewDocument(null)}
        previewUrl={previewDocument?.url || ''}
        fileType={previewDocument?.fileType as 'pdf' | 'excel' | 'image'}
      />
    </div>
  );
};

export const WorkflowLinear = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  return (
    <WorkflowPresentation
      steps={STATIC_WORKFLOW_STEPS}
      accessToken={accessToken}
    />
  );
});
const mock_acord_url = 'https://faistaging.blob.core.windows.net/user-documents/c97eab2a-3f5e-48c7-8aa4-1bb6ccd3ecb4_ACORD_125_CA_2023-01_fillable.pdf?st=2024-11-27T21%3A28%3A45Z&se=2024-11-28T21%3A28%3A45Z&sp=rcw&sv=2024-05-04&sr=b&sig=BhaOipms2WTLfLec6H2eAuNF7gPUdTF/6Ujz4tv77OM%3D';
const mock_lossrun_url = 'https://faistaging.blob.core.windows.net/user-documents/ce2d2a83-f313-434b-a910-416b9ba4668e_loss-run-sample.pdf?st=2024-11-27T21%3A44%3A34Z&se=2024-11-28T21%3A44%3A34Z&sp=rcw&sv=2024-05-04&sr=b&sig=py4mNXmpOtH2Ny4%2B5PCfZ7sTaltUyHaa/qlUBfjo/%2B8%3D';
const mock_sov_url = 'https://faistaging.blob.core.windows.net/user-documents/e84af939-e508-4fc6-a8df-4ffa6038ccfe_sov_template--1-.xlsx?st=2024-11-27T21%3A48%3A43Z&se=2024-11-28T21%3A48%3A43Z&sp=rcw&sv=2024-05-04&sr=b&sig=cDijYKshmY7jPHABN8m%2BMsaLRp2Kf9KAuiJ2hk7H9u8%3D';
const STATIC_WORKFLOW_STEPS: WorkflowStep[] = [

  {
    id: 'email',
    title: 'Received email from the broker',
    status: 'completed',
    type: 'document',
    documents: [
      {
        name: 'View output',
        url: '#',
      }
    ]
  },
  {
    id: 'classification',
    title: 'Classified documents into ACORD, Loss Runs, SOV',
    status: 'completed',
    type: 'document',
    documents: [
      {
        name: 'ACORD_25_2024.pdf',
        url: mock_acord_url,
        fileType: 'pdf',
      },
      {
        name: 'ACORD_70_2024.pdf',
        url: mock_acord_url,
        fileType: 'pdf',
      },
      {
        name: 'Loss_Runs_2023_Q4.pdf',
        url: mock_lossrun_url,
        fileType: 'pdf',
      },
      {
        name: 'Loss_Runs_2024_Q1.pdf',
        url: mock_lossrun_url,
        fileType: 'pdf',
      },
      {
        name: 'SOV_Schedule_2024.xlsx',
        url: mock_sov_url,
        fileType: 'excel',
      },
      {
        name: 'SOV_Appendix_2024.xlsx',
        url: mock_sov_url,
        fileType: 'excel',
      },
      {
        name: 'View output',
        url: '#',
      }
    ]
  },
  {
    id: 'acord_extraction',
    title: 'Extracted information from ACORD',
    status: 'completed',
    type: 'document',
    sectionTitle: 'ACORD forms',
    documents: [
      {
        name: 'ACORD_25_2024.pdf',
        url: mock_acord_url,
        fileType: 'pdf',
      },
      {
        name: 'ACORD_70_2024.pdf',
        url: mock_acord_url,
        fileType: 'pdf',
      },
      {
        name: 'View output',
        url: '#',
      }
    ]
  },
  {
    id: 'loss_run_extraction',
    title: 'Extracted information from Loss Run',
    status: 'completed',
    type: 'document',
    sectionTitle: 'Loss Runs',
    documents: [
      {
        name: 'Loss_Run_Report_2023.pdf',
        url: mock_lossrun_url,
        fileType: 'pdf',
      },
      {
        name: 'Loss_Run_Report_2024.pdf',
        url: mock_lossrun_url,
        fileType: 'pdf',
      },
      {
        name: 'View output',
        url: '#',
      }
    ]
  },
  {
    id: 'sov_extraction',
    title: 'Extracted information from SOV',
    status: 'completed',
    type: 'document',
    sectionTitle: 'SOVs',
    documents: [
      {
        name: 'MSI_Insurance_Policy_2024.xlsx',
        url: mock_sov_url,
        fileType: 'excel',
      }
    ]
  },
  {
    id: 'email_response',
    title: 'Generated email response',
    status: 'completed',
    type: 'document',
    documents: [
      {
        name: 'View email',
        url: '#',
      }
    ]
  }
];
