import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNodeApi } from "../../hooks/useNodeApi";
import { SubmissionEmailData } from "../../services/api";
import { ChevronRight, FileText } from "lucide-react";
import toast from 'react-hot-toast';
import { Card, CardContent } from "../ui/card";
import { withRequiredAuthInfo } from "@propelauth/react";
import axios from 'axios';
import { SubmissionAIChat } from './SubmissionAIChat'
import { ScrollArea } from "../ui/scroll-area";
import DOMPurify from 'dompurify';
import React from "react";

const POLL_INTERVAL = 5000;
const POLL_TIMEOUT = 300000;

const sanitizeAndRenderHTML = (content: string) => {
    if (typeof content !== 'string') {
        return null;
    }
    try {
        if (!content) return null;

        const sanitizedContent = DOMPurify.sanitize(content, {
            ALLOWED_TAGS: ['p', 'br', 'strong', 'em', 'ul', 'ol', 'li', 'a', 'span', 'div'],
            ALLOWED_ATTR: ['href', 'target', 'rel', 'class'],
            RETURN_DOM: false,
            RETURN_DOM_FRAGMENT: false,
            WHOLE_DOCUMENT: false,
            FORCE_BODY: true
        });

        if (!sanitizedContent.trim()) return null;

        return <div className="email-content" dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
    } catch (error) {
        console.error('HTML sanitization failed:', error);
        return <div className="text-red-500">Error rendering content</div>;
    }
};

export const SubmissionDetail = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
    const navigate = useNavigate();
    const { id: submissionId } = useParams<{ id: string }>();
    const { fetchSubmissionEmailById } = useNodeApi(accessToken);
    const [submissionEmail, setSubmissionEmail] = useState<SubmissionEmailData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isOffline, setIsOffline] = useState(false);
    const lastPollTimeRef = useRef(0);

    const loadCompareLog = useCallback(async (bypassTimeCheck = false) => {
        if (!submissionId) {
            setError('No comparison ID provided.');
            setIsLoading(false);
            return;
        }

        const now = Date.now();
        if (!bypassTimeCheck && now - lastPollTimeRef.current < POLL_INTERVAL) {
            return;
        }

        let retries = 0;
        const maxRetries = Math.ceil(POLL_TIMEOUT / POLL_INTERVAL);

        const attemptFetch = async (): Promise<void> => {
            try {
                const result = await fetchSubmissionEmailById(submissionId);
                if (result) {
                    setSubmissionEmail(result);
                    setIsLoading(false);
                } else if (retries < maxRetries) {
                    retries++;
                    setTimeout(() => attemptFetch(), POLL_INTERVAL);
                } else {
                    setError('Connection to server lost. Please check back later.');
                    setIsLoading(false);
                }
                lastPollTimeRef.current = now;
                if (isOffline) {
                    setIsOffline(false);
                    toast.success("Connection restored. Compare log updated.");
                }
            } catch (error) {
                console.error("Failed to fetch submission emails:", error);
                if (retries > 0 && axios.isAxiosError(error) && error.status === 404) {
                    navigate('/404');
                    return;
                }
                if (retries < maxRetries) {
                    retries++;
                    setTimeout(() => attemptFetch(), POLL_INTERVAL);
                } else {
                    if (!isOffline) {
                        setIsOffline(true);
                        toast.error("Failed to fetch submission emails. Please try again.");
                    }
                    setError('Failed to fetch submission emails. Please try again.');
                    setIsLoading(false);
                }
            }
        };

        attemptFetch();
    }, [submissionId, fetchSubmissionEmailById, isOffline, navigate]);

    const loadCompareLogRef = useRef(loadCompareLog);

    useEffect(() => {
        loadCompareLogRef.current = loadCompareLog;
    }, [loadCompareLog]);

    useEffect(() => {
        loadCompareLogRef.current(true);
    }, []);

    return (
        <div>
            <div className="bg-gray-50 py-8 px-8">
                <div className="max-w-7xl mx-auto flex items-center">
                    <div className="flex items-center text-md text-gray-500">
                        <span
                            className="hover:text-gray-700 cursor-pointer"
                            onClick={() => navigate(`/submission/workflow/${submissionId}`)}
                        >
                            Workflow
                        </span>
                        <ChevronRight className="h-4 w-4 mx-2" />
                        <span className="font-medium text-gray-900">Email Conversation</span>
                    </div>
                </div>
            </div>

            <div className="flex-1 flex overflow-hidden bg-gray-50 px-6">
                <div className="flex gap-4 w-full max-w-7xl mx-auto">
                    <div className="flex-1">
                        {isLoading ? (
                            <SubmissionDetailSkeleton />
                        ) : error ? (
                            <SubmissionDetailError error={error} />
                        ) : submissionEmail ? (
                            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                                <div className="p-4">
                                    <h2 className="text-lg font-semibold mb-2">
                                        {submissionEmail.title || "Untitled Email"}
                                    </h2>
                                    <div className="h-px bg-gray-200 mb-4 my-4" />
                                    <ScrollArea
                                        className="h-[calc(100vh-200px)]"
                                        type="auto"
                                    >
                                        <div className="space-y-6">
                                            <Card className="shadow-none border-none">
                                                <CardContent className="p-4">
                                                    <div className="space-y-2">
                                                        <div className="flex items-center">
                                                            <span className="text-sm font-medium text-blue-600">
                                                                Further AI
                                                            </span>
                                                            <span className="text-xs text-gray-500 ml-2">
                                                                automated@furtherai.com
                                                            </span>
                                                        </div>
                                                        <EmailContentErrorBoundary>
                                                            <div className="text-sm text-gray-700">
                                                                {submissionEmail?.body_without_previous_email_html
                                                                    ? sanitizeAndRenderHTML(submissionEmail.body_without_previous_email_html)
                                                                    : "No content available"
                                                                }
                                                            </div>
                                                        </EmailContentErrorBoundary>

                                                        {submissionEmail?.output_email_attachment_blobs && submissionEmail?.output_email_attachment_blobs?.length > 0 && (
                                                            <div className="flex flex-wrap gap-2 mt-4">
                                                                {submissionEmail.output_email_attachment_blobs.map((document) => (
                                                                      <a
                                                                      key={document.name}
                                                                      href={document.url}
                                                                      target="_blank"
                                                                      rel="noopener noreferrer"
                                                                      className="inline-flex items-center text-sm text-gray-700 bg-white border border-gray-200 rounded-md px-3 py-1.5 hover:bg-gray-100"
                                                                    >
                                                                            <div className="flex items-center space-x-2">
                                                                                <div className="w-7 h-7 rounded-md bg-purple-50 flex items-center justify-center">
                                                                                    <FileText className="w-3 h-3 text-purple-600" />
                                                                                </div>
                                                                                <span className="text-xs text-gray-900 truncate max-w-[180px]">
                                                                                    {document.name}
                                                                                </span>
                                                                            </div>
                                                                    </a>
                                                                    ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </CardContent>
                                            </Card>

                                            <div className="h-px bg-gray-200" />

                                            <Card className="shadow-none border-none">
                                                <CardContent className="p-4">
                                                    <div className="space-y-2">
                                                        <div className="flex items-center">
                                                            <span className="text-sm font-medium text-green-600">
                                                                {submissionEmail?.title?.split(' - ')[0] || "User"}
                                                            </span>
                                                            <span className="text-xs text-gray-500 ml-2">
                                                                {submissionEmail?.title?.split(' - ')[1] || ""}
                                                            </span>
                                                        </div>
                                                        <EmailContentErrorBoundary>
                                                            <div className="text-sm text-gray-700">
                                                                {submissionEmail?.previous_email_body_html
                                                                    ? sanitizeAndRenderHTML(submissionEmail.previous_email_body_html)
                                                                    : "No content available"
                                                                }
                                                            </div>
                                                        </EmailContentErrorBoundary>

                                                        {submissionEmail?.previous_email_attachment_blobs && submissionEmail?.previous_email_attachment_blobs?.length > 0 && (
                                                            <div className="flex flex-wrap gap-2 mt-4">
                                                                {submissionEmail.previous_email_attachment_blobs.map((document) => (
                                                                    <a
                                                                        key={document.name}
                                                                        href={document.url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className="inline-flex items-center text-sm text-gray-700 bg-white border border-gray-200 rounded-md px-3 py-1.5 hover:bg-gray-100"
                                                                    >
                                                                        <div className="flex items-center space-x-2">
                                                                            <div className="w-7 h-7 rounded-md bg-purple-50 flex items-center justify-center">
                                                                                <FileText className="w-3 h-3 text-purple-600" />
                                                                            </div>
                                                                            <span className="text-xs text-gray-900 truncate max-w-[180px]">
                                                                                {document.name}
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </ScrollArea>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div className="w-80">
                        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden h-[calc(100vh-105px)]">
                            <SubmissionAIChat />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

function SubmissionDetailSkeleton() {
    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
            <div className="p-4">
                <div className="h-8 w-64 bg-gray-200 rounded mb-6 animate-pulse"></div>
                <div className="h-64 w-full bg-gray-200 rounded animate-pulse"></div>
            </div>
        </div>
    );
}

function SubmissionDetailError({ error }: { error: string }) {
    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
            <div className="p-4">
                <h1 className="text-xl font-semibold mb-6">Error</h1>
                <p className="text-red-500 mb-4">{error}</p>
            </div>
        </div>
    );
}

interface EmailContentErrorBoundaryProps {
  children: React.ReactNode;
}

interface EmailContentErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class EmailContentErrorBoundary extends React.Component<
  EmailContentErrorBoundaryProps,
  EmailContentErrorBoundaryState
> {
  constructor(props: EmailContentErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): EmailContentErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Error rendering email content</div>;
    }
    return this.props.children;
  }
}
