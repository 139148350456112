import React, { useRef, useState } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { AutoResizeTextarea } from "../AutoResizeTextArea"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../ui/dropdown-menu';
import { Sparkle, Save, Search, Star, Plus, ArrowRight } from 'lucide-react';
import toast from 'react-hot-toast';

interface Template {
  name: string;
  question: string;
  isFavorite: boolean;
}

interface Document {
  _id: string;
  filename: string;
}

interface ChatInputProps {
  onSend: (message: string, documents?: File[]) => void;
  isLoading?: boolean;
  userClass?: {
    getOrgs: () => Array<{
      orgMetadata: Record<string, unknown>;
    }>;
  };
}

export function PromptInput({ onSend, isLoading = false, userClass }: ChatInputProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [message, setMessage] = useState('');
  const [isSaveDropdownOpen, setIsSaveDropdownOpen] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedDocuments, setUploadedDocuments] = useState<Document[]>([]);

  const templates: Template[] = [
    {
      name: "Default Template",
      question: "Default question template",
      isFavorite: true
    }
  ];

  const handleSend = () => {
    if (!message.trim()) return;

    onSend(message.trim(), uploadedDocuments.length > 0 ? [] : undefined);

    setMessage('');
    setUploadedDocuments([]);
  };

  const handleSaveTemplate = () => {
    if (!newTemplateName.trim()) {
      toast.error("Template name cannot be empty.");
      return;
    }

    toast.success("Template saved successfully.");
    setNewTemplateName('');
    setIsSaveDropdownOpen(false);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length > 0) {
      setUploadedDocuments(prev => [
        ...prev,
        ...files.map(file => ({
          _id: Math.random().toString(36).substr(2, 9),
          filename: file.name
        }))
      ]);
    }
  };

  return (
    <div className="flex flex-col gap-3 p-4">
      <div className="flex flex-col gap-3">
        <div className="flex flex-wrap gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="h-9 px-3 text-sm font-normal border-gray-200"
              >
                <Sparkle className="h-4 w-4 mr-2" />
                Load prompts
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-64 bg-white"
              align="start"
            >
              <div className="p-2">
                <div className="relative">
                  <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  <Input
                    type="text"
                    placeholder="Search for a prompt"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-8 pr-2 py-1 w-full text-sm focus:ring-0"
                  />
                </div>
              </div>
              {templates
                .filter(template => template.name.toLowerCase().includes(searchQuery.toLowerCase()))
                .map((template) => (
                  <DropdownMenuItem
                    key={template.name}
                    onSelect={() => setMessage(template.question)}
                    className="flex items-center justify-between"
                  >
                    <span>{template.name}</span>
                    {template.isFavorite && (
                      <Star className="h-4 w-4 text-gray-800" />
                    )}
                  </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
          </DropdownMenu>

          <DropdownMenu open={isSaveDropdownOpen} onOpenChange={setIsSaveDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="h-9 px-3 text-sm font-normal border-gray-200"
                onClick={(e) => {
                  const org = userClass?.getOrgs()[0];
                  const isFurtherAI = org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];
                  if (!isFurtherAI) {
                    e.preventDefault();
                    toast.error("Contact your admin to enable this feature.");
                  }
                }}
              >
                <Save className="h-4 w-4 mr-2" />
                Save prompt
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[400px] p-4 bg-white" align="start">
              <div className="space-y-4">
                <p className="text-sm text-gray-600">
                  This will save the current <span className="font-medium text-gray-900">prompt</span> state as a preset which you can access later or share with others.
                </p>

                <div className="space-y-2">
                  <label className="text-sm">Name</label>
                  <div className="flex gap-2">
                    <Input
                      value={newTemplateName}
                      onChange={(e) => setNewTemplateName(e.target.value)}
                      placeholder="Enter template name"
                      className="flex-1"
                    />
                    <Button
                      onClick={handleSaveTemplate}
                      className="bg-black text-white hover:bg-gray-800 px-4"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div className="space-y-2">
          <AutoResizeTextarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Ask a follow-up question"
            className="min-h-[44px] max-h-[200px] px-3 py-2 text-sm border-gray-200 rounded-md resize-none focus:ring-0 focus:border-gray-300"
          />

          <div className="flex items-center gap-2">
            <input
              type="file"
              multiple
              onChange={handleFileUpload}
              className="hidden"
              id="file-upload"
              ref={fileInputRef}
              accept="image/*,.pdf"
            />
            <Button
              variant="outline"
              size="sm"
              asChild
              className="h-9 px-3 text-sm font-normal text-gray-600 hover:text-gray-900 hover:bg-gray-50"
            >
              <label htmlFor="file-upload" className="cursor-pointer flex items-center gap-2">
                <Plus className="h-4 w-4" />
                Add attachments
                {uploadedDocuments.length > 0 && (
                  <span className="text-xs bg-gray-100 px-2 py-0.5 rounded-full">
                    {uploadedDocuments.length}
                  </span>
                )}
              </label>
            </Button>
            <Button
              onClick={handleSend}
              disabled={isLoading || !message.trim()}
              className="ml-auto h-9 w-9 p-0 rounded-full bg-gray-900 text-white hover:bg-gray-800"
            >
              <ArrowRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
