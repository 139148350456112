import { Outlet } from 'react-router-dom';
import { HomeSidebarV1, SIDEBAR_WIDTH, SIDEBAR_WIDTH_ICON } from './HomeSidebarV1';
import { useSidebarState } from './useSidebarState';
import { cn } from '../lib/utils';

export const MainLayoutV1 = ({ className }: { className?: string }) => {
  const { isCollapsed, handleSidebarToggle } = useSidebarState();

  return (
    <div className="flex h-screen overflow-hidden">
      <HomeSidebarV1 onSidebarToggle={handleSidebarToggle} />
      <main
        className={cn(
          "flex-1 overflow-y-auto transition-all duration-300 ease-in-out bg-gray-50",
          className
        )}
        style={{
          marginLeft: isCollapsed ? SIDEBAR_WIDTH_ICON : SIDEBAR_WIDTH,
          width: `calc(100% - ${isCollapsed ? SIDEBAR_WIDTH_ICON : SIDEBAR_WIDTH})`
        }}
      >
        <Outlet />
      </main>
    </div>
  );
};
// MainLayout is the layout for the app, it includes the sidebar and the main content area
